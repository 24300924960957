import axios from "axios";
import { setDocuments } from "./localbase";

export default {
    /**
     *
     * @param userID id of the logges user
     * @returns array of documenti
     *
     * Get all documents for the logged user
     */
    async getDocumenti(dipendenteID: string) {
        const data = new FormData();
        data.append("limit", "100");
        //data.append("where[documenti_dipendenti_dipendente]", dipendenteID);
        data.append("where", `documenti_dipendenti_dipendente = '${dipendenteID}' OR documenti_dipendenti_categoria = '3'`);
        data.append("orderby", "documenti_dipendenti_creation_date");
        data.append("orderdir", "desc");
        console.log(`${process.env.VUE_APP_BASE_URL}`);

        const response = await axios.post(`rest/v1/search/documenti_dipendenti`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        response.data.data.forEach((document) => {
            setDocuments(document);
        });

        //console.log(response);
        return response.data.data;
    },

    async getCategorieDocumenti() {
        const data = new FormData();
        data.append("orderby", "documenti_dipendenti_categoria_id");
        data.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/documenti_dipendenti_categoria`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    async setLettura(docId, userId) {
        const data = new FormData();
        data.append("doc_id", docId);
        data.append("user_id", userId);

        //const response = await axios.post(`http://192.168.11.124/firegui_puma/modulo-hr/documenti/readDocument`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/documenti/readDocument`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        console.log(response);
        return response;
    },

    async checkCircolari(user_id: string) {
        const data = new FormData();
        data.append(
            "where",
            `documenti_dipendenti_categoria = '3' AND documenti_dipendenti_id NOT IN (SELECT documenti_letture_documento_id FROM documenti_letture WHERE documenti_letture_user_id = '${user_id}')`
        );
        data.append("orderby", "documenti_dipendenti_creation_date");
        data.append("orderdir", "DESC");

        const response = await axios.post(`rest/v1/search/documenti_dipendenti`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response;
    },
};
