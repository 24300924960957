
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButtons,
    IonButton,
    IonInput,
    menuController,
    modalController,
} from "@ionic/vue";
import { refresh, attach, menu } from "ionicons/icons";

import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiDocumenti from "@/services/documenti";
import DocumentoDetail from "@/components/documenti/DocumentoDetail.vue";

export default {
    name: "Documenti",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButtons,
        IonButton,
        IonInput,
    },
    setup() {
        const router = useRouter();

        const loading = ref(false);
        const presenze = ref([]);
        const documenti = ref([]);
        /* const documenti_generali = ref([]);
        const documenti_cedolino = ref([]);
        const documenti_circolari = ref([]); */

        const showGenerali = ref(true);
        const showCedolini = ref(false);
        const showCircolari = ref(false);

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const selectedDocumentType = ref("1"); //Documenti generali di default

        const openMenu = () => {
            menuController.open("app-menu");
        };

        function setActiveDocuments(type) {
            selectedDocumentType.value = type;
            if (type === "generali") {
                showGenerali.value = true;
                showCedolini.value = false;
                showCircolari.value = false;
            } else if (type === "cedolino") {
                showCedolini.value = true;
                showGenerali.value = false;
                showCircolari.value = false;
            } else {
                showCircolari.value = true;
                showCedolini.value = false;
                showGenerali.value = false;
            }
        }

        function setSwitchLabel(label) {
            if (label === "Documenti generali") {
                return "Generali";
            }
            if (label === "Circolari / Comunicazioni") {
                return "Circolari";
            }
            return label;
        }

        function setAttachmentUrl(documento) {
            return `${process.env.VUE_APP_BASE_URL}uploads/${documento.documenti_dipendenti_file}`;
        }

        /**
         * ! Get all documenti
         */
        async function loadDocuments() {
            loading.value = true;
            try {
                documenti.value = await apiDocumenti.getDocumenti(dipendenteID);
                //documenti_generali.value = documenti.value.filter((document) => document.documenti_dipendenti_categoria == "1");
                //documenti_cedolino.value = documenti.value.filter((document) => document.documenti_dipendenti_categoria == "2");
                //documenti_circolari.value = documenti.value.filter((document) => document.documenti_dipendenti_categoria == "3");
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei documenti", "toast_danger");
            }
            loading.value = false;
        }
        loadDocuments();
        /**
         * ! Get all documenti categorie
         */

        /* async function loadCircolari() {
            loading.value = true;
            try {
                const res = await await apiDocumenti.getCircolari();
                if (res.status === 200 && res.data.status === 0) {
                    documenti_circolari.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta delle circolari", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle circolari", "toast_danger");
            }
            loading.value = false;
        }
        loadCircolari() */

        const categorieDocumenti = ref([]);
        async function loadDocumentiCategorie() {
            loading.value = true;
            try {
                categorieDocumenti.value = await apiDocumenti.getCategorieDocumenti();
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle categorie di documenti", "toast_danger");
            }
            loading.value = false;
        }
        loadDocumentiCategorie();

        /**
         * ! Filter all documents to show based on current selected document type
         * ! also based on search query
         */
        /* const documentsToShow = computed(() => {
            return documenti.value.filter((documento) => {
                if (documento.documenti_dipendenti_categoria === selectedDocumentType.value) return documento;
            });
        }); */
        const searchQuery = ref("");
        const documentsToShow = computed(() => {
            return documenti.value.filter((documento) => {
                // Filtra in base alla tipologia selezionata
                if (documento.documenti_dipendenti_categoria !== selectedDocumentType.value) {
                    return false;
                }

                // Esegui la ricerca nel titolo o nel testo del documento
                const searchTerm = searchQuery.value.toLowerCase();
                if (!searchTerm) {
                    return true; // Se la stringa di ricerca è vuota, mostra tutti i documenti della tipologia selezionata
                }

                const nameMatch =
                    documento.documenti_dipendenti_nome_documento && documento.documenti_dipendenti_nome_documento.toLowerCase().includes(searchTerm);
                const titleMatch = documento.documenti_dipendenti_titolo && documento.documenti_dipendenti_titolo.toLowerCase().includes(searchTerm);
                const textMatch = documento.documenti_dipendenti_testo && documento.documenti_dipendenti_testo.toLowerCase().includes(searchTerm);
                return nameMatch || titleMatch || textMatch;
            });
        });

        /**
         * ! Check id document is expired to show badge with label "Scaduto"
         */
        function documentoScaduto(documento) {
            const scadenza = documento.documenti_dipendenti_data_scadenza;
            const dataScadenza = moment(scadenza).valueOf();
            const dataAttuale = moment().valueOf();

            if (dataScadenza < dataAttuale) {
                return true;
            }
        }

        function docCircolare(documento) {
            if (documento.documenti_dipendenti_categoria === "3") {
                return true;
            }
        }

        /**
         * ! Set correct background for data scadenza if expired
         */
        const scadenzaDocumento = computed(() => {
            return (scadenza) => {
                let className = "";
                const dataScadenza = moment(scadenza).valueOf();
                const dataAttuale = moment().valueOf();

                if (dataScadenza < dataAttuale) {
                    className = "danger";
                } else {
                    className = "light";
                }
                return className;
            };
        });

        /**
         * ! Imposta documento come letto, se già letto non fa nulla
         */
        async function setDocumentRead(docId) {
            try {
                const res = await apiDocumenti.setLettura(docId, userID);
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        }

        /**
         * Open modal to show document detail
         */
        async function openDetail(documento, dipendente_id) {
            await setDocumentRead(dipendente_id);

            const modal = await modalController.create({
                component: DocumentoDetail,
                componentProps: {
                    data: documento,
                },
            });
            return modal.present();
        }

        return {
            loading,
            document,
            attach,
            presenze,
            documenti,
            scadenzaDocumento,
            dateFormat,
            refresh,
            //Nuovi campi
            documentoScaduto,
            loadDocuments,
            openMenu,
            menu,
            //Suddivisione documenti
            /*  documenti_generali,
            documenti_cedolino,
            documenti_circolari, */
            setActiveDocuments,
            showGenerali,
            showCedolini,
            showCircolari,
            setAttachmentUrl,
            //Nuova gestione categorie documenti
            categorieDocumenti,
            documentsToShow,
            selectedDocumentType,
            setDocumentRead,
            docCircolare,
            setSwitchLabel,
            openDetail,
            //ricerca
            searchQuery,
        };
    },
};
